import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { SVG } from './icons';
import ScrollContext from '../contexts/scroll';

import styles from './submenu.module.scss';

const Submenu = ({ items }) => {
	const { isScrollingDown, scrollY } = useContext(ScrollContext);

	const classNames = [
		styles.wrapper,
		(scrollY > 0 && styles.wrapperFixed) || '',
		(isScrollingDown && styles.wrapperHidden) || ''
	];

	return (
		<nav className={classNames.filter(Boolean).join(' ')}>
			<div className={styles.container}>
				{items.map(({ slug, name, icon: { publicURL: src } }) => {
					return (
						<Link
							to={slug}
							key={slug}
							partiallyActive={true}
							className={styles.item}
							activeClassName={styles.itemActive}>
							<SVG path={src} className={styles.svg} />
							<div className={styles.label}>{name}</div>
						</Link>
					);
				})}
			</div>
		</nav>
	);
};

Submenu.defaultProps = {
	items: []
};

Submenu.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			slug: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			icon: PropTypes.shape({
				publicURL: PropTypes.string.isRequired
			}).isRequired
		})
	).isRequired
};

export default Submenu;
